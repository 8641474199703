/*
    Footer
*/

.footer {
    margin: 40px -10px 0;
    padding: 10px;
    background: url('#{$img_dir}/background-footer.gif') 0 0 repeat-x #5b6573;
}

.footer-container {
    margin: 0 auto;
    max-width: 960px;
    padding: 20px 0;
    position: relative;
}

.footer-container:after {
    clear: both;
    content: "";
    display: block;
}

.footer-right {
    margin-top: 20px;
}

.footer-signup .text {
    display: block;
}

.footer-signup .button {
    float: none;
}

.to-top-link {
    position: absolute;
    top: -25px;
    right: 0;
    padding: 0;
}

.to-top-link a {
    width: 38px;
    height: 38px;
    background-position: 0 -222px;
    position: relative;
    -webkit-transition: top 0.2s ease;
    -moz-transition: top 0.2s ease;
    -ms-transition: top 0.2s ease;
    -o-transition: top 0.2s ease;
    transition: top 0.2s ease;
}

.to-top-link a:hover,
.to-top-link a:focus {
    top: -2px;
    ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.footer-left {
    color: #dbdbdb;
    font-family: "Tahoma", "Verdana", sans-serif;
    font-size: 12px;
    line-height: 1.5;
}

.footer-left a {
    color: inherit;
    text-decoration: none;
}

.footer-left a:focus,
.footer-left a:hover {
    text-decoration: underline;
}

.footer-social {
    height: 33px;
}

.footer-social li {
    float: left;
    margin: 0 6px 0 0;
}

.footer-social a {
    float: left;
    width: 34px;
    height: 33px
}

.facebook-square {
    background-position: -34px -189px;
}

.linkedin-square {
    background-position: -68px -189px;
}

.twitter-square {
    background-position: 0 -189px;
}

.footer-nav {
    text-transform: uppercase;
    padding: 1.8em 0 1.8em 0;
    letter-spacing: 0.05em;
}

.footer-nav li {
    margin: 0;
    display: inline;
}