/* -- -- -- --
    SECTIONS
-- -- -- -- -- */

/*
    Home
*/

.home-buttons {
    padding: 20px 0 20px 0;
    text-align: center;
}

.content .home-buttons ul {
    padding: 0;
}

.content .home-buttons li {
    display: inline-block;
    margin: 5px;
    padding: 0;
}

.home-buttons a {
    color: #5B6573;
    font: normal normal 1.25em 'TuffyRegular', arial, sans-serif;
    text-align: center;
    width: 305px;
    height: 173px;
    padding: 20px 10px 0 10px;
    margin: 0;
    display: block;
    background-image: url('#{$img_dir}/home-buttons.jpg');
    background-repeat: no-repeat;
    float: left;
    text-decoration: none;
}

.home-buttons .open a {
    background-position: 0 0;
}

.home-buttons .inhouse a {
    background-position: -325px 0;
}

.home-buttons .elearning a {
    background-position: 0 -193px;
}

.home-buttons .consultancy a {
    background-position: -325px -193px;
}

/*
    Contact
*/

.content .contact-table {
    border: 0;
    box-shadow: none;
    margin: 0 auto 1em;
    width: auto;
}

.content .contact-table th,
.content .contact-table td {
    border: 0;
    padding: 0.5em;
    vertical-align: top;
}

.content .contact-table th {
    background: transparent;
    color: inherit;
    font-weight: bold;
    text-align: right;
}

.contact-left {
    float: left;
    width: 330px;
}

.contact-right {
    float: right;
    width: 330px;
}

.contact-form {
    max-width: 400px;
    margin: 1em auto;
}

.contact-form .text,
.contact-form textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.5em !important;
    padding: 10px;
    width: 100%;
}

.contact-form textarea {
    height: 200px;
}

/*
    Subscribe
*/

.newsletter-form {
    width: 310px;
    margin: 2em auto 0 auto;
}

/*
    Courses
*/

.page-links {
    border: 1px solid #D9DFD3;
    background: #f3f3f3;
    padding: 20px;
}

.course-introduction {
    padding: 20px 0 0 0;
}

.page-links.hide {
    display: none;
}

.content .page-links ul {
    padding: 0;
}

.content .page-links li {
    background-position: -248px -193px;
    padding: 0 0 0 14px;
    display: block;
}

.page-links a {
    color: #353535;
    display: block;
    text-decoration: none;
}

.page-links a:hover,
.page-links a:focus {
    text-decoration: underline;
}

.content table {
    -moz-box-shadow: 0 2px 6px #E0E0E0;
    -webkit-box-shadow: 0 2px 6px #E0E0E0;
    box-shadow: 0 2px 6px #E0E0E0;
    margin: 10px 0 30px 0;
    /*width: 680px;*/
    width: 100%;
}

.double td {
    width: 50%;
}


.content td,
.content th {
    border: 1px solid #E0E0E0;
}

.content .four th {
    padding: 12px 16px 12px 16px;
}

.content td {
    padding: 12px 16px 0 16px;
}

.content .single th,
.content .double th {
    height: 60px;
    text-transform: uppercase;
    text-align: center;
}

.content .Black th {
    background: url('#{$img_dir}/th-black.jpg') 0 0 repeat-x #1b1b1b;
    color: #fff;
}

.content .Yellow th {
    background: url('#{$img_dir}/th-yellow.jpg') 0 0 repeat-x #FFDF87;
    color: #494949;
}

.content .Green th {
    background: url('#{$img_dir}/th-green.jpg') 0 0 repeat-x #5F9558;
    color: #fff;
}

.content .Grey th {
    background: url('#{$img_dir}/th-grey.jpg') 0 0 repeat-x #979797;
    color: #fff;
}

.content .Blue th,
th {
    background: #5B6573;
    color: #fff;
}

.content .four th {
    text-align: left;
    font-weight: normal;
}

.contact-action {
    padding-top: 1em;
    clear: both;
}

.contact-action a {
    color: #353535;
    display: block;
    text-decoration: none;
    padding: 0 0 0 48px;
    font-weight: bold;
    background: url('#{$img_dir}/contact-action.gif') 0 6px no-repeat transparent;
    line-height: 17px;
}

.contact-action a:hover,
.contact-action a:focus {
    color: #39c;
}

/* Media > 480px */

@media screen and (min-width: 480px) {

    .strapline {
        padding: 35px 40px 0 40px;
    }

}

/* Media > 640px */

@media screen and (min-width: 640px) {

    .header {
        background-image: url(#{$img_dir}/background-banner.jpg);
        background-position: 0 30px;
        background-repeat: repeat-x;
        height: 120px;
        overflow: hidden;
        position: relative;
    }

    h1 a {
        margin: 0;
        position: absolute;
        left: 0;
        top: 15px;
    }

    .header-details {
        position: absolute;
        right: 0;
        bottom: 20px;
        font-size: 18px;
        text-align: right;
    }

    .header-details p {
        padding: 25px 0 0 0;
    }

    .header-social {
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
    }

    .nav-main {
        font-size: 14px;
    }

    .nav-main li {
        float: left;
        position: relative;
    }

    .nav-main li+li {
        border: 0;
    }

    .nav-main li+li:before {
        background: #43474d;
        content: "";
        display: block;
        height: 30px;
        position: absolute;
        left: 0;
        top: 5px;
        width: 1px;
    }

    .nav-main button {
        display: none;
    }

    .nav-closed ul {
        display: block;
    }

    .strapline h2 {
        font-size: 32px;
    }

    .inner-page-banner h2 {
        font-size: 30px;
    }

}

/* Media > 800px */

@media screen and (min-width: 800px) {

    .content {
        float: right;
        width: 71%;
    }

    .sidebar {
        float: left;
        width: 26%;
    }

    .nav-main {
        font-size: 16px;
    }

    .nav-main a {
        padding: 0 20px;
    }

    .inner-page-banner {
        height: 120px;
    }

    .inner-page-banner-wrap {
        display: table;
        table-layout: fixed;
    }

    .inner-page-banner-left,
    .inner-page-banner-right {
        display: table-cell;
        height: 120px;
        padding: 0 30px;
        vertical-align: middle;
    }

    .inner-page-banner-left {
        text-align: left;
    }

    .footer-container {
        padding: 10px 0;
    }

    .footer-right {
        float: right;
        margin-top: 10px;
    }

    .footer-left {
        float: left;
        margin-top: 10px;
    }

    .footer-signup .button {
        float: right;
    }

}

/* Media > 960px */

@media screen and (min-width: 960px) {

    .nav-main a {
        padding: 0 30px;
    }

}

/* Quick contact box */

.quick-contact {
    background-color: $blue;
    color: #fff;
    font-size: 0.875em;
    margin: 0 -10px;
}

.quick-contact h3 {
    color: inherit;
    margin: 0;
}

.quick-contact p {
    margin: 1em 0;
    padding: 0;
}

.quick-contact a {
    color: inherit;
}

.quick-contact .section {
    background-color: $blue;
    padding: 2em;
    position: relative;
    z-index: 20;
}

.quick-contact .section> :first-child,
.quick-contact .section> :first-child> :first-child {
    margin-top: 0;
}

.quick-contact .section> :last-child,
.quick-contact .section> :last-child> :last-child {
    margin-bottom: 0;
}

.quick-contact .section.details {
    background-color: #1c6a9b;
    font-weight: bold;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
}

.quick-contact .section.details a {
    text-decoration: none;
}

.quick-contact .message {
    font-style: italic;
}

.quick-contact .error {
    color: #ffce90;
}

.quick-contact .field {
    display: block;
    margin: 1em 0;
}

.quick-contact .field.half {
    float: left;
    margin: 0 0 0 2em;
    width: calc(50% - 1em);
}

.quick-contact .field.half:nth-of-type(2n-1) {
    margin-left: 0;
}

.quick-contact .field.half .text-label {
    margin-top: 0;
}

.quick-contact .field::after {
    clear: both;
    content: "";
    display: block;
}

.quick-contact .field.submit {
    text-align: right;
}

.quick-contact .text-label {
    display: block;
    margin: 0.5em 0;
}

.quick-contact .text-label .error {
    float: right;
    font-size: 0.875em;
    font-style: italic;
}

.quick-contact .text-input {
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0;
    border: 0;
    box-sizing: border-box;
    box-shadow: none;
    color: #333;
    font: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0.25em;
    transition: background-color 200ms ease;
    width: 100%;
}

.quick-contact .text-input:focus {
    background-color: rgba(255, 255, 255, 0.875);
}

.quick-contact textarea.text-input {
    height: 8em;
}

.quick-contact .box-label {
    box-sizing: border-box;
    display: block;
    float: left;
    margin-left: 2em;
    width: calc(50% - 1em);
}

.quick-contact .box-label:nth-of-type(2n-1) {
    margin-left: 0;
}

.quick-contact .box-label input {
    margin-right: 0.25em;
    margin-top: -0.125em;
    vertical-align: middle;
}

.quick-contact .button-input {
    background-color: #fff;
    border: 0;
    border-radius: 0.25em;
    color: $blue;
    font: inherit;
    font-weight: bold;
    line-height: inherit;
    padding: 0.75em 1.5em;
}

.quick-contact .button-input:focus,
.quick-contact .button-input:hover {
    box-shadow: 0 0.25em 0 rgba(0, 0, 0, 0.25);
}

.quick-contact .button-input:active {
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: none;
}

/* Quick contact box - with JavaScript */

.quick-contact.active {
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    margin: 0;
    position: fixed;
    right: 0;
    top: 4em;
    transition: box-shadow 400ms ease, right 400ms ease;
    width: 35em;
    z-index: 999;
}

.quick-contact.active.hidden {
    box-shadow: none;
    display: block;
    right: -35em;
}

.quick-contact .quick-contact-toggle {
    background-color: #ff9d21;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    border-style: solid;
    border-color: #e68d1e;
    border-width: 1px 1px 0 1px;
    border-radius: 0.25em 0.25em 0 0;
    box-shadow: 0 0 2em rgba(0, 0, 0, 0.25);
    color: #333;
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0.75em 1.5em;
    position: absolute;
    right: 100%;
    text-decoration: none;
    bottom: 100%;
    text-shadow: 0 0 2px #fff;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    z-index: 10;
}

@media screen and (max-width: 40em) {
    .quick-contact {
        display: none;
    }

    .quick-contact-toggle {
        display: none !important;
    }
}