/*
    Banner / Slideshow
*/

.banner {
    /*width: 960px;*/
    max-width: 960px;
    margin: 0 auto 0 auto;
    padding: 30px 0 0 0;
}

.slideshow {
    /*width: 960px;*/
    height: 331px;
    position: relative;
    border-bottom: 1px solid #e7e7e7;
    background: #f3f3f3;
    overflow: hidden;
}

.slides {
    width: 960px;
    height: 280px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.strapline {
    padding: 25px 20px 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.strapline h2 {
    text-transform: uppercase;
    text-shadow: 1px 1px 2px #fff;
    font-size: 24px;
    line-height: 1em;
    margin: 0 0 0.6em 0;
    text-align: left;
}

.strapline p {
    max-width: 320px;
    font-weight: bold;
}

.slide-nav {
    position: absolute;
    bottom: 14px;
    right: 11px;
}

.slide-nav a {
    display: inline-block;
    width: 27px;
    height: 23px;
    padding: 4px 0 0 0;
    text-align: center;
    font: normal normal 1.15em 'TuffyRegular', arial, sans-serif;
    margin: 0 0 0 1px;
    background: #5B6573;
    color: #fff;
    text-decoration: none;
    -webkit-transition: background 2s ease;
    -moz-transition: background 2s ease;
    -ms-transition: background 2s ease;
    -o-transition: background 2s ease;
    transition: background 2s ease;
}

.slide-nav a.activeSlide {
    background: $blue;
}

.slide-nav a:hover,
.slide-nav a:focus {
    text-decoration: none;
}


.inner-page-banner {
    background: url('#{$img_dir}/inner-page-banner.png') 0 0 no-repeat transparent;
    background-size: cover;
    max-width: 960px;
    padding: 10px;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.inner-page-banner-left {
    font-weight: bold;
    margin-bottom: 10px;
}

.inner-page-banner h2 {
    text-transform: uppercase;
    text-shadow: 1px 1px 2px #fff;
    font-size: 24px;
    line-height: 1;
    margin: 0;
    text-align: center;
}