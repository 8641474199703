//
// Transitions
//

$transition-duration: 200ms !default;

@mixin transition-colors {
    transition: background-color $transition-duration,
        border-color $transition-duration,
        color $transition-duration,
        opacity $transition-duration,
        fill $transition-duration;
}

@mixin transition-transformations {
    transition: transform $transition-duration;
}