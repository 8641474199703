/*
    Header
*/

.header {
    margin: 0 auto 0 auto;
    max-width: 960px;
    text-align: center;
}

h1 {
    overflow: hidden;
    margin: 0;
}

h1 a {
    width: 230px;
    height: 85px;
    margin: 10px auto;
    background: url('#{$img_dir}/logo.jpg') 0 0 no-repeat transparent;
}

.header-details {
    color: #2687C3;
    font-family: "TuffyRegular", "Arial", sans-serif;
    font-weight: normal;
    letter-spacing: 0.1em;
    margin: 5px 0;
}

.header-details p {
    margin: 0;
}

.header-details a {
    color: #2687C3;
    text-decoration: none;
}

.header-details a:hover,
.header-details a:focus {
    text-decoration: underline;
}

.header-social {
    display: none;
}

.header-social li {
    display: inline
}

.header-social a {
    width: 29px;
    height: 29px;
    float: left;
    margin-left: 4px;
}

.facebook-grey {
    background-position: 0 -160px;
}

.linkedin-grey {
    background-position: -29px -160px;
}

.twitter-grey {
    background-position: -58px -160px;
}

.registration-links {
    @include media-query(micro) {
        position: absolute;
        top: 1em;
        right: calc(54px + (29px + 4px));
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .link {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        color: black;

        &:hover {
            color: $blue;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            position: relative;
            top: -1px;
        }

        &__icon svg {
            fill: $blue;
        }
    }

    .separator {
        margin: 0 1em;
        position: relative;
        top: -1px;
    }
}