/* -- -- --
    GLOBAL
-- -- -- -- */

@font-face {
    font-family: 'TuffyRegular';
    src: url('#{$font_dir}/Tuffy-webfont.eot');
    src: url('#{$font_dir}/Tuffy-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$font_dir}/Tuffy-webfont.woff') format('woff'),
    url('#{$font_dir}/Tuffy-webfont.ttf') format('truetype'),
    url('#{$font_dir}/Tuffy-webfont.svg#TuffyRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    max-height: 999999px;
    /* fix chrome on mobile devices boosting font size */
}

body {
    background: #f7f7f7;
    border-top: 5px solid #7e7e7e;
    color: #353535;
    font: normal normal 85% arial, sans-serif;
    line-height: 160%;
    min-width: 280px;
    padding: 0 10px;
}

.content {
    font-size: 1em;
}

::-moz-selection {
    background-color: $blue;
}

::selection {
    background-color: $blue;
}

p,
ul {
    padding: 0 0 0.8em 0;
    margin: 0;
}

h2,
h3,
h4,
h5 {
    font-family: 'TuffyRegular', arial, sans-serif;
    font-weight: normal;
    padding: 0;
    margin: 0 0 0.8em 0;
    color: $blue;
    /* color: #39c; */
}

h2 {
    font-size: 1.6em;
    margin: 0 0 1em 0;
    text-align: right;
}

h3 {
    font-size: 1.55em;
}

.content h3 {
    font-size: 1.6em;
}

h4 {
    font-size: 1.3em;
}

h5 {
    font-size: 1.2em;
}

p {
    clear: right;
}

.content ul {
    padding-left: 12px;
    padding-bottom: 0.2em;
    list-style-type: none;
}

.content li {
    padding-left: 12px;
    padding-bottom: 0.6em;
    background: url('#{$img_dir}/bullet.png') 0 8px no-repeat transparent;
}

blockquote {
    padding: 20px 20px 50px 20px;
    margin: 0;
    background: url('#{$img_dir}/blockquote.png') bottom right no-repeat #f3f3f3;
}

blockquote p {
    padding: 0;
}

a {
    color: $blue;
    text-decoration: underline;
}

a:hover,
a:focus {
    text-decoration: none;
}

.yellow-text {
    color: #FAB555;
    font-weight: bold;
}

.green-text {
    color: #588851;
    font-weight: bold;
}

.black-text {
    color: #000;
    font-weight: bold;
}

.alignleft,
.alignright,
.alignnone,
.aligncenter {
    border: 1px solid #efefef;
    background: #fff;
    height: auto;
    padding: 9px;
}

.wp-caption.alignleft,
.wp-caption.alignright,
.wp-caption.alignnone,
.wp-caption.aligncenter {
    padding: 9px 0 9px 8px;
    color: #757575;
    font-size: 0.8em;
    height: auto;
    line-height: 1.3em;
    font-style: italic;
}

.alignleft,
.alignright,
.wp-caption.alignleft,
.wp-caption.alignright {
    max-width: 30%;
}

.wp-caption p {
    padding: 1.4em 20px 0 0;
}

.wp-caption p,
.wp-caption img {
    position: relative;
    left: 1px;
    height: auto;
    max-width: 95%;
}

.alignleft {
    margin: 5px 20px 10px 0;
    float: left;
    clear: left;
}

.alignright {
    margin: 5px 0 10px 20px;
    float: right;
    clear: right;
}

.alignnone {
    clear: both;
    margin: 5px 0 10px 0;
}

.aligncenter {
    clear: both;
    margin: 5px auto 10px auto;
}

.sprite,
hr,
.content li.sprite {
    background: url('#{$img_dir}/sprite.png') 0 0 no-repeat transparent;
}

hr {
    background-position: 0 -386px;
    background-repeat: repeat-x;
    border: 0;
    margin: 0.8em 0 1.8em 0;
    height: 3px;
}

.main {
    margin: 0 auto;
    max-width: 960px;
}

dd {
    padding: 0;
    margin: 0;
}

dt {
    float: left;
    width: 270px;
    text-align: right;
    padding: 0;
    margin: 0;
    font-weight: bold;
}

dd {
    float: right;
    width: 400px;
    margin: 0;
    padding: 0;
}

.error {
    color: red;
    font-weight: 600;
}

/*
    Helper Classes
*/

.hidden {
    display: none;
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.nav-list,
.nav-list ul {
    list-style-type: none;
    padding: 0;
}

.nav-list li {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.text-replace {
    text-indent: -1000px;
    overflow: hidden;
    direction: ltr;
    display: block;
}