.site-header-notice {
    background-color: $blue;
    color: white;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $blue;
        position: absolute;
        left: -100%;
        top: 0;
    }

    &:after {
        content: '';
        width: 10px;
        height: 100%;
        background-color: $blue;
        position: absolute;
        right: -10px;
        top: 0;
    }

    &__text {
        padding: 7px 0;

        .message {
            font-weight: bold;
            text-align: center;
            font-size: .889rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &__icon {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                background-color: white;
                color: $blue;
                margin-right: 10px;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                margin: 0;
                padding: 0;
            }

            &--shake {
                animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                perspective: 1000px;
                animation-delay: .2s;
            }
        }
    }
}