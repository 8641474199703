/*
    Sign Up Widget
*/

.widget h4 {
    font: normal normal 1.4em 'TuffyRegular', arial, sans-serif;
    padding: 0 0 0 39px;
    height: 30px;
    line-height: 30px;
    background-position: 0 -275px;
    color: #fff;
    margin: 0 0 12px 0;
    display: block !important;
}

.widget label {
    display: none;
}

.widget .text {
    width: 203px;
}

.widget .error {
    color: #fff;
}

.widget .recaptcha {
    position: relative;
    padding-top: 76px;

    .g-recaptcha {
        position: absolute;
        top: 0;
        left: -44px;
        transform: scale(.7125);
    }
}