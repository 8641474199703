.course-content {
    $space: 10px;

    margin-bottom: -#{$space};

    &__title+&__sections {
        margin-top: 47px;
    }

    &__introduction {
        &+.course-content__sections {
            margin-top: 47px;
        }

        p:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__section {
        @include transition-colors;
        background-color: $black-coral;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        text-decoration: none;
        padding: .625rem .875rem;
        font-weight: bold;
        font-family: TuffyRegular;
        letter-spacing: 1px;
        margin-bottom: $space;

        &:hover,
        &--active {
            background-color: $blue;
        }

        &--collapsed+.subsection-wrapper {
            display: none;
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .icon svg {
            fill: white;
            max-width: 24px;
            max-height: 24px;

            &.hidden {
                display: none;
            }
        }
    }

    &__subsection {
        @extend .course-content__section;
        background-color: $chalice-silver;
        text-transform: none;
        font-weight: normal;
        margin-left: $space;
        color: $jet-black;

        &:hover,
        &--active {
            background-color: $platinum;
        }

        &--collapsed+.subsection-content-wrapper {
            display: none;
        }
    }

    &__subsection-content {
        padding-left: calc(#{$space} + 13px);
    }

    &__subsection-content .content {
        margin: 0;
        padding: 0;
        float: none;
        width: 100%;
        margin-bottom: $space;
        margin-top: 2.5 * $space;

        &:last-of-type {
            margin-bottom: 2.1 * $space;
        }

        &__heading {
            color: $black-coral;
            font-size: 1rem;
            font-weight: bold;
            margin-top: -.3125rem;
        }

        &__video-section {
            padding-left: $space;

            .video {
                display: block;
                font-weight: bold;
                font-family: TuffyRegular;
                padding: 0;
            }

            .video+.video {
                margin-top: .1875rem;
            }
        }
    }
}