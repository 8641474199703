.course-registration-form {

    %contact-form-checkbox {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        opacity: 0 !important;
        visibility: hidden !important;

        &:checked~label:before {
            background-color: $black-coral !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e") !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: 50% / 50% 50% !important;
        }
    }

    %contact-form-checkbox-label {
        padding-left: 40px !important;
        position: relative;

        &:before {
            @include transition-colors;
            content: "" !important;
            width: 25px !important;
            height: 25px !important;
            position: absolute !important;
            top: -3px !important;
            left: 0 !important;
            border: 1px solid $quick-silver !important;
            cursor: pointer !important;
        }
    }

    @extend .contact-form;
    max-width: 442px;

    label {
        display: block;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 1px;
        color: $black-coral;
    }

    .required {
        color: red;
        position: relative;
        top: 4px;
        left: 6px;
        font-size: 1.5em;
    }

    .text {
        height: 40px;
        box-shadow: none;
        margin-bottom: 15px;

        &+.info-text {
            margin-top: -2px;
        }
    }

    .info-text {
        @extend .required;
        text-align: right;
        font-size: 1em;
        padding-right: 7px;
        padding-bottom: 0;
        margin-bottom: 15px;
        font-weight: bold;

        >.asterisk {
            @extend .required;
            left: auto;
            top: 6px;
        }

        &+.checkbox-wrap {
            margin-top: 47px;
        }
    }

    .checkbox-wrap {
        display: flex;
        flex-direction: column-reverse;
    }

    .checkbox-wrap+.checkbox-wrap {
        @include media-query(nano) {
            margin-top: 1em;
        }

        margin-top: 3em;
    }

    .checkbox-wrap+.recaptcha {
        margin-top: 22px;
    }

    // Custom checkbox
    input[type="checkbox"] {
        @extend %contact-form-checkbox;
    }

    input[type="checkbox"]+label {
        @extend %contact-form-checkbox-label;
        display: block;
        font-weight: normal;
        height: 25px;

        >a {
            color: inherit;
        }
    }

    .recaptcha+.button[type="submit"] {
        margin-top: 50px;
        margin-bottom: 0;
    }

    .button[type="submit"] {
        box-shadow: none;
        border-radius: 0;
        padding: 25px;
        display: inline-flex;
        align-items: center;
        text-shadow: none;
        font-size: 1.375em;
        font-weight: normal;
        padding: 33px 25px;
    }

    .date-field {
        background-image: url('#{$img_dir}/calendar.png');
        background-size: 18px 20px;
        background-position: center right 15px;
        background-repeat: no-repeat;
    }
}