/* -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
    BASE.CSS
    This is taken from Normalize.css, edited and modified by Jamie Woolgar
-- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- */

article,aside,details,figcaption,figure,footer,header,hgroup,nav,section{display:block;}
audio,canvas,video{display:inline-block;*display:inline;*zoom:1;}audio:not([controls]){display:none;}
[hidden]{display:none;}html{font-size:100%;overflow-y:scroll;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}
body{margin:0;font-size:13px;line-height:1.231;}
body,button,input,select,textarea{font-family:sans-serif;color:#222;}
::-moz-selection{color:#fff;text-shadow:none;}
::selection{color:#fff;text-shadow:none;}
a:focus{outline:0;}a:hover,a:active{outline:0;}
abbr[title]{border-bottom:1px dotted;}
b,strong{font-weight:bold;}
blockquote{margin:1em 40px;}
dfn{font-style:italic;}
hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0;}
ins{background:#ff9;color:#000;text-decoration:none;}
mark{background:#ff0;color:#000;font-style:italic;font-weight:bold;}
pre,code,kbd,samp{font-family:monospace,monospace;_font-family:'courier new',monospace;font-size:1em;}
pre{ white-space: pre; white-space: pre-wrap; word-wrap: break-word; }
q{quotes:none;}
q:before,q:after{content:"";content:none;}
small{font-size:85%;}
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}
sup{top:-0.5em;}
sub{bottom:-0.25em;}
ul,ol{margin:1em 0;padding:0 0 0 40px;}
dd{margin:0 0 0 40px;}
nav ul,nav ol{list-style:none;list-style-image:none;margin:0;padding:0;}
img{border:0;-ms-interpolation-mode:bicubic;vertical-align:middle;}
svg:not(:root){overflow:hidden;}
figure{margin:0;}
form{margin:0;}
fieldset{border:0;margin:0;padding:0;}
label{cursor:pointer;}
legend{border:0;*margin-left:-7px;padding:0;}
button,input,select,textarea{font-size:100%;margin:0;vertical-align:baseline;*vertical-align:middle;}
button:focus,input:focus,select:focus,textarea:focus{outline:none;}
button,input{line-height:normal;*overflow:visible;}
table button,table input{*overflow:auto;}
button,input[type="button"],input[type="reset"],input[type="submit"]{cursor:pointer;-webkit-appearance:button;}
input[type="checkbox"],input[type="radio"]{box-sizing:border-box;}
input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}
input[type="search"]
::-webkit-search-decoration{-webkit-appearance:none;}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
textarea{overflow:auto;vertical-align:top;resize:vertical;}
table{border-collapse:collapse;border-spacing:0;}
td{vertical-align:top;}
@media print{
*{background:transparent !important;color:black !important;text-shadow:none !important;filter:none !important;-ms-filter:none !important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
abbr[title]:after{content:" (" attr(title) ")";}
pre,blockquote{border:1px solid #999;page-break-inside:avoid;}
thead{display:table-header-group;}
tr,img{page-break-inside:avoid;}
img{max-width:100% !important;}
@page{margin: 0.5cm;}
p,h2,h3{orphans:3;widows:3;}
h2,h3{page-break-after:avoid;}
}