/*
    Main Navigation
*/

.nav-main {
    line-height: 40px;
    margin: 0 auto;
    max-width: 960px;
    background: #5B6573;
    overflow: hidden;
    font: normal normal 16px 'TuffyRegular', arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.nav-main li {
    display: block;
    margin: 0;
    position: relative;
    left: -0px;
}

.nav-main li+li {
    border-top: 1px solid #7c848f;
}

.nav-main a {
    color: #fff;
    display: block;
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    padding: 0 10px;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
}

.nav-main a:hover,
.nav-main a:focus {
    text-decoration: underline;
}

.nav-main .current_page_item a {
    background-color: $blue !important;
}

.nav-main .current_page_item a:hover,
.nav-main .current_page_item a:focus {
    text-decoration: none;
}

.nav-main button {
    background: #7c848f;
    border: 0;
    color: #fff;
    height: 40px;
    width: 100%;
}

.nav-closed ul {
    display: none;
}

/*
    Sidebar
*/

.sidebar {
    padding: 30px 0 0 0;
}

.nav-sidebar {
    margin: 14px 0 40px 0;
    border-top: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    background: #f3f3f3;
    font-size: 13px;
    line-height: 22px;
}

.nav-sidebar li {
    border-bottom: 1px solid #e7e7e7;
    display: block;
}

.nav-sidebar ul ul li {
    border-bottom: 0;
}

.nav-sidebar a {
    display: block;
    padding: 8px 0 8px 30px;
    color: #353535;
    text-decoration: none;
    background: url('#{$img_dir}/sprite.png') 11px -65px no-repeat transparent;
}

.nav-sidebar a:hover,
.nav-sidebar a:focus,
.nav-sidebar .current_page_item a,
.nav-sidebar .current_page_ancestor>a,
.nav-sidebar a.on {
    color: $blue;
    text-decoration: none;
    background-position: 11px 15px;
}

.nav-sidebar ul ul a {
    font-size: 12px;
    line-height: 20px;
    position: relative;
    top: -5px;
    padding: 4px 0 4px 30px;
    background: url('#{$img_dir}/sprite.png') -227px -345px no-repeat transparent;
}

.nav-sidebar ul ul .current_page_item a,
.nav-sidebar ul ul a:hover,
.nav-sidebar ul ul a:focus {
    background: url('#{$img_dir}/sprite.png') -227px -297px no-repeat transparent;
}

.testimonial-widget {
    border: 1px solid #E6E5E5;
    background: #f4f4f4;
    margin: 30px 0 0 0;
    font-family: georgia, 'times new roman', serif;
    text-align: center;
    font-style: italic;
}

.testimonial-widget p {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.testimonial-widget .testimonial {
    background: url('#{$img_dir}/sprite.png') 0 -389px no-repeat transparent;
    margin: -22px 0 0 0;
    padding-top: 32px;
    color: #898787;
}

.testimonial-widget .testimonial-credit {
    font-size: 0.9em;
    color: #9a9a9a;
}