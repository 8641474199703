// Image directory
$img_dir: '../../images';

// Font directory
$font_dir: '../../fonts';

// Colours
$black-coral: #5D6575;
$quick-silver: #A4A4A4;
$chalice-silver: #B5B4B4;
$platinum: #E4E4E4;
$jet-black: #353535;
$blue: #2385C2;