/*
    Form Elements
*/

label {
    display: none;
}

.text,
textarea {
    height: 33px;
    /*line-height: 33px !important;*/
    vertical-align: middle;
    border: 1px solid #bbb;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 1px 1px 1px 10px;
    width: 297px;
    font: normal normal 1em arial, sans-serif;
    -moz-box-shadow: inset 3px 3px 7px #ccc;
    -webkit-box-shadow: inset 3px 3px 7px #ccc;
    box-shadow: inset 3px 3px 7px #ccc;
    margin: 0 0 12px 0;
    color: #353535;
}

.text.placeholder,
textarea.placeholder {
    color: #a9a9a9;
}

.text:focus,
textarea:focus {
    color: #353535;
    -moz-box-shadow: inset 3px 3px 7px #aaa;
    -webkit-box-shadow: inset 3px 3px 7px #aaa;
    box-shadow: inset 3px 3px 7px #aaa;
}

textarea {
    height: 100px;
}

.err {
    color: #E15C51;
}

.err img {
    position: relative;
    top: -2px;
}

form .err {
    position: relative;
    top: -12px;
    display: block;
}


.button {
    background: $blue;
    border: 0;
    -moz-border-radius: 3px;
    border-radius: 3px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    color: #fff;
    padding: 2px 20px 2px 20px;
    text-shadow: 1px 1px 2px #222;
    -moz-box-shadow: 1px 1px 3px #333;
    -webkit-box-shadow: 1px 1px 3px #333;
    box-shadow: 1px 1px 3px #333;
    margin: 10px 0 12px 0;
    font: normal bold 1em arial, sans-serif;

    &:hover {
        background-color: darken($blue, 10%);
    }
}

.button:focus {
    -moz-box-shadow: inset 1px 1px 3px #333;
    -webkit-box-shadow: inset 1px 1px 3px #333;
    box-shadow: inset 1px 1px 3px #333;
}